import TrackingEvent from '~/lib/analytics/TrackingEvent'

/**
 * This event is triggered when an faq section is clicked.
 */
export default class FaqSectionClickedEvent extends TrackingEvent {
  private faqSection: string
  private component: string

  constructor(faqSection: string, component: string) {
    super('Faq Section Clicked')
    this.faqSection = faqSection
    this.component = component
  }

  public properties() {
    return {
      action: `FAQ ${this.faqSection}`,
      component: this.component,
      path: window.location.pathname,
      url: window.location.href,
    }
  }
}

export const TRACKED_FAQ_SECTION_KEY = 'faq-section-tracked'
